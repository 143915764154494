import React, { FC, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { TextField, TableCell, TableRow, Link, Button } from '@material-ui/core'
import { snackBar } from 'store'
import useStore from 'store'
import { Edit, Save, Close, Link as LinkIcon, Delete } from '@material-ui/icons'
import styled from 'styled-components'
import { Switch } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import api from 'service/api'

const Actions = styled.div`
  display:flex;
  flex-direction: row;
`
const AutoCompleteContainer = styled(Autocomplete)`

`
const StyledButton = styled(Button)`
  padding: 2px 4px;
  min-width: 10px;
`

const getPriorityTitleByValue = (val) => val == 0 ? 'רגיל' : 'גבוהה'
const genericErrorText = 'קיימת בעיה בשרת כרגע. אנא נסו מאוחר יותר.'

const LabelSectionItem: FC = ({ label, entityId, onRefresh, entityLabels }) => {
    const {t} = useTranslation('communitySettings')
    const { uiStore } = useStore()
    const [status, setStatus] = useState(label.status)
    const [isEditMode, setEditMode] = useState(false)
    const [labelFormState, setLabelFormState] = useState({
      name: '',
      sendingPriority: { value: 0, name: getPriorityTitleByValue(0) },
      status: 1,
    })

    useEffect(() => {
      setEditMode(false)
      setLabelFormState({
        name: '',
        sendingPriority: { value: 0, name: getPriorityTitleByValue(0) },
        status: 1,
      })
    }, [entityId])

    const onEditEvent = () => {
      setEditMode(true)
      setLabelFormState({
        name: label.name,
        sendingPriority: { value: label.sendingPriority, name: getPriorityTitleByValue(label.sendingPriority) },
        status: label.status,
      })
    }

    const onChangeStatus = async (status) => {
      await api.editEntityLabel(label.id, {
        description: label.description,
        id: label.id,
        name: label.name,
        sendingPriority: label.sendingPriority,
        status
      })

      onRefresh(entityId)
    }
    
    const onCloseEditEvent = () => {
      setEditMode(false)
    }

    const onAttachEvent = async () => {
      const labels = await api.getAllLabels()
      
      if (!Array.isArray(labels)) {
        snackBar.showError(genericErrorText)
        return
      }

      const existLabel = labels.find(l => l.name == labelFormState.name)
      if (!existLabel) {
        snackBar.showError(genericErrorText)
        return
      }

      if (existLabel) {
        const relations = await api.getEntityLabelLinks(entityId)
        const relation = relations.find(r => r.entityId == entityId  && r.labelId == label.id)
        
        if (!relation) {
          snackBar.showError(genericErrorText)
          return
        }
        
        await api.unlinkLabelWithEntity(relation.id)
        await api.linkLabelWithEntity(entityId, existLabel.id)
        setEditMode(false)
        onRefresh(entityId)
      }
    }
    
    const onSaveEvent = async () => {
      const res = await api.editEntityLabel(label.id, {
        description: label.description,
        id: label.id,
        name: labelFormState.name,
        sendingPriority: labelFormState.sendingPriority.value,
        status: labelFormState.status
      })

      if (res === null) {
        uiStore.openWarningModal({
          text: 'תג זה כבר קיים באירגון אחר האם ברצונך להישתמש בו?',
          secondText: labelFormState.name,
          okText: `הוסף`,
          cancelText:t`ביטול`,
          okAction: async () => {
            onAttachEvent()
          }
        })
      } else {
        setEditMode(false)
        onRefresh(entityId)
      }
    } 

    const onDeleteEvent = async () => {
      uiStore.openWarningModal({
        text: t`remove:removeLabel`,
        secondText: label.name,
        okAction: async () => {
          const relations = await api.getEntityLabelLinks(entityId)
          const relation = relations.find(r => r.entityId == entityId  && r.labelId == label.id)
        
          if (!relation) {
            snackBar.showError(genericErrorText)
            return
          }
        
          await api.unlinkLabelWithEntity(relation.id)
          onRefresh(entityId)
        }
      })
    }

    const isDisabled = () => {
      const labelNames = entityLabels.filter(l => l.id != label.id).map(l => l.name)

      return labelFormState.name.trim() == '' || ( 
        label.name.trim() == labelFormState.name.trim() &&
        labelFormState.sendingPriority.value == label.sendingPriority &&
        labelFormState.status == label.status
      ) || labelNames.includes(labelFormState.name.trim())
    }

    return (
      <>
      <TableRow>
      {!isEditMode &&
        <>
        <TableCell component="th" scope="row">
          {getPriorityTitleByValue(label.sendingPriority)}
        </TableCell>
        <TableCell>
          {label.name}
        </TableCell>
        <TableCell component="th" scope="row">
          <Switch
            color="primary"
            value={status == 1}
            onChange={({target}) => {
              setStatus(target.checked ? 1 : 0)
              onChangeStatus(target.checked ? 1 : 0)
            }}
            checked={status == 1}
          />
        </TableCell>
        <TableCell width={100}>
          <Actions>
            <StyledButton onClick={onEditEvent}><Edit/></StyledButton>
            <StyledButton onClick={onDeleteEvent}><Delete/></StyledButton>
          </Actions>
        </TableCell>
        </>
      }

      {isEditMode &&
        <>
        <TableCell component="th" scope="row">
          <AutoCompleteContainer
            value={labelFormState.sendingPriority}
            defaultValue={{value: 0, name: 'רגיל'}}
            disableClearable
            onChange={(value: any, v: any) => {
              setLabelFormState({ ...labelFormState, sendingPriority: v })
            }}
            options={[{value: 1, name: 'גבוהה'}, {value: 0, name: 'רגיל'}]}
            placeholder={`תיעדוף`}
            renderOption={(option: any) => option?.name}
            getOptionLabel={(option: any) => option?.name}
            renderInput={(params) =>
              <TextField {...params} value={labelFormState.sendingPriority.value || 0} label={`תיעדוף`} variant="outlined" />}
          />
        </TableCell>
        <TableCell>
          <TextField
            id={'label_name'}
            required
            label={t`name`}
            variant="outlined"
            value={labelFormState.name}
            onChange={({target}) => {
              setLabelFormState({ ...labelFormState, name: target.value })
            }}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          <Switch
            color="primary"
            value={status == 1}
            onChange={({target}) => {
              setStatus(target.checked ? 1 : 0)
              onChangeStatus(target.checked ? 1 : 0)
            }}
            checked={status == 1}
          />
        </TableCell>
        <TableCell width={100}>
          <Actions>
          <StyledButton onClick={onCloseEditEvent}><Close/></StyledButton>
          <StyledButton disabled={isDisabled()} onClick={onSaveEvent}><Save/></StyledButton>
          </Actions>
        </TableCell>
        </>
      }
      </TableRow>
      </>
    )
}

export default observer(LabelSectionItem)