import React from 'react'

import { action, computed, IObservableArray, observable, ObservableMap, reaction, runInAction, toJS } from 'mobx'
import type { UserGroup, Group, Description, ID, Provider, McEntity } from 'utils/types'
import { GroupType } from 'utils/types'

import orgs from 'store/orgs.store'
import members from 'store/members.store'

import api from 'service/api'

import membersStore from 'store/members.store'
import userStore from 'store/user.store'

import { LOADERS } from 'utils/loaders'
import type { LastMessage, User } from 'utils/models'
import { AssociatedGroupsLabel } from 'utils/models'
import vls, { storageKeys } from 'utils/vls'
import { getMembersAndManagersCount } from 'utils/dataUtils'
import { isAfter } from 'date-fns'
import deepClone from 'lodash/cloneDeep'
import { Path } from 'core/Routes'
import { canEditUsersGroup, userCanSeeAssociatedGroups, userIsDirector } from 'utils/roles'
import { isTotzeret } from 'service/whiteLable'
interface serverCouncil {
  id: number,
  smsCounter: number,
  name: string

}

export enum sendTypes {
  sendSmsAndEmailTrue,
  sendSmsFalse,
  sendEmailFalse,
  sendSmsAndEmailFalse
}
class GroupsStore {

  constructor() {
    reaction(
      () => orgs.currentOrgId,
      id => {
        if (id) {
          // this.getGroupMembers(id)
          this.getGroups()
          if (userCanSeeAssociatedGroups()) {
            this.getCommunityAssociatedGroups()
            //this.getAssociatedLabels()
          }
        }
      },
      { fireImmediately: true }
    )
    reaction(
      () => this.currentGroupId,
      id => {
        vls.set(storageKeys.currentGroupId, id)
        // id && this.getGroupMembers(id < 0 ? -id : id)
      }
    )
    // reaction(
    //   () => orgs.currentOrgId,
    //   id => this.initGroupId()
    // )
    reaction(
      () => this.groupMembers,
      members => this.onGroupMembersChanged()
    )
  }
  defaultGroupDescription: Description = {
    color: isTotzeret ? "#efefef" : "#707070",
    icon: "default",
    iconColor: isTotzeret ? "#b3b3b3" : "#E0E0E0",
    category: "ברירת מחדל",
    subCategory: "",
    subCategoryKey: "default",
    fabColor: '#9d9d9d',
    iconName: '',
    sound: 'default'
  }

  @observable groups: IObservableArray<UserGroup> = observable([])
  @observable supportGroups: IObservableArray<UserGroup> = observable([])
  @observable openSupportCallGroups: IObservableArray<UserGroup> = observable([])
  @observable regionalCouncil: IObservableArray<serverCouncil> = observable([])

  @observable loadingGroups: boolean = false
  @observable supportGroupsLoading: boolean = true
  @observable lastMessages: ObservableMap<number, LastMessage> = new ObservableMap()
  @action setLastMessage(groupId: number, message: LastMessage) {
    this.lastMessages.set(groupId, message)
  }

  @observable isMembersModalOpen = false
  @observable groupMembersLoading = false
  @action setGroupMemberLoading = (bool: boolean) => {
    this.groupMembersLoading = bool
  }
  @action closeMembersModal = () => this.isMembersModalOpen = false
  @action openMembersModal = () => {
    if (this.currentGroupId) {
      if (this.currentGroup?.group.group_is_member && canEditUsersGroup()) {
        const groupUsers: UserGroup[] = membersStore.orgMembers.map(member => ({
          community: this.currentGroup?.community || orgs.currentOrgId!,
          is_member: true,
          is_organizer: false,
          is_sender: this.currentGroup?.group.group_is_sender || false,
          allowShare: this.currentGroup?.group.allowShare || false,
          sendSMS: this.currentGroup?.group.sendSMS || false,
          sendEmail: this.currentGroup?.group.sendEmail || false,
          is_subscriber: this.currentGroup?.group.group_is_subscriber || false,
          is_hide: false,
          is_favourite: this.currentGroup?.group.group_is_favourite || false,
          group: this.currentGroup?.group!,
          user: member.user_id,
          userData: member
        }))
        const sortedlist = groupUsers.sort((a, b) => !a.userData?.family_name ? 1 : (!b.userData?.family_name ? -1 : (a.userData?.family_name.localeCompare(b.userData?.family_name, 'he', { sensitivity: 'base' }))))
        this._groupMembers.replace(sortedlist)
      }
      else {
        this.setGroupMemberLoading(true)
      }
      this.isMembersModalOpen = true
      this.initGroupMembers(Math.abs(this.currentGroupId)).then(() => {
        runInAction(() => {
          this.setGroupMemberLoading(false)
        })
      })
    }
  }

  @computed get currentGroups(): Group[] {
    const res = (this.groups.map(g => g.group)
      .filter(group => group.community_id === orgs.currentOrgId)
      .sort((a, b) => {
        return (new Date(this.lastMessages.get(b.id)?.lastMessage.message.created || 0).getTime() - new Date(this.lastMessages.get(a.id)?.lastMessage.message.created || 0).getTime())
        // return ((this.lastMessages.get(a.id)||0) && 1) - ((this.lastMessages.get(b.id)||0) && 1)
      })) || []
    return res
  }
  @computed get currentUserGroups(): UserGroup[] {
    const res = toJS((this.groups
      .filter(userGroup => userGroup.group.community_id === orgs.currentOrgId)
      .sort((a, b) => {
        return (new Date(this.lastMessages.get(b.group.id)?.lastMessage.message.created || 0).getTime() - new Date(this.lastMessages.get(a.group.id)?.lastMessage.message.created || 0).getTime())
        // return ((this.lastMessages.get(a.id)||0) && 1) - ((this.lastMessages.get(b.id)||0) && 1)
      })) || [])
    return res
  }
  @computed get filteredCurrentUserGroups(): UserGroup[] {
    return this.currentUserGroups.filter(el => el.is_favourite)
  }
  @computed get currentUserGroup(): UserGroup | undefined {
    return this.groups.find(el => el.group.id === this.currentGroupId)
  }

  @computed get currentSupportGroups() {
    const res = this.supportGroups.slice().sort((a, b) => {
      return (new Date(this.lastMessages.get(b.group.id)?.lastMessage.message.created || 0).getTime() - new Date(this.lastMessages.get(a.group.id)?.lastMessage.message.created || 0).getTime())
      // return ((this.lastMessages.get(a.id)||0) && 1) - ((this.lastMessages.get(b.id)||0) && 1)
    })
    return res
  }
  @computed get currentOpenSupportGroups() {
    return this.openSupportCallGroups.slice().sort((a, b) => {
      const aTime = new Date(this.lastMessages.get(a.group.id)?.lastMessage.message.created || 0).getTime()
      const bTime = new Date(this.lastMessages.get(b.group.id)?.lastMessage.message.created || 0).getTime()
      return bTime - aTime
    })
  }

  @action async getSupportGroups() {
    // let counter = -1
    // users get only these
    const supportGroups = await api.getSupportGroups(orgs.currentOrgId || 1, userIsDirector())
    const currentGroup = this.currentGroup
    let replacement = null;
    supportGroups?.forEach(i => {
      if (i.group.is_organizer && i.group.parent_id) {
        i.group.id = -i.group.parent_id
      }
      if (i.group.id < 0 && i.group.parent_id) {
        i.group.id = -i.group.parent_id
        // counter--
      }
      i.group.type = GroupType.SUPPORT
      if (currentGroup && currentGroup.group.id < 0 && i.group.parent_id === currentGroup.group.parent_id) {
        replacement = i.group.id
      }
    })
    // admins get these as collapsed items
    const openSupportCall = await api.getOpenSupportCalls(orgs.currentOrgId || 1)
    openSupportCall?.forEach(i => {
      const item = supportGroups?.find(g => g.group.parent_id === i.group.parent_id)
      i.group.description = { ...item?.group?.description, ...i.group.description }
      i.group.type = GroupType.SUPPORT
      i.group.name = i.group.name.replace('private', '|') || i.group.name
      i.group.group_is_favourite = item?.group.group_is_favourite || false
      i.group.group_is_member = item?.group.group_is_member || false
      i.is_organizer = item?.is_organizer || false
      i.group.group_is_sender = item?.group.group_is_sender || false
      i.group.allowShare = item?.group.allowShare || false
      i.group.sendSMS = item?.group.sendSMS || false
      i.group.sendEmail = item?.group.sendEmail || false
      i.group.type = GroupType.SUPPORT
    })
    this.supportGroups.replace(supportGroups || [])
    if (replacement) {
      this.currentGroupId = replacement
    }
    this.openSupportCallGroups.replace(openSupportCall || [])
    // if (!this.currentGroupId && location.pathname === Path.SERVICECALL) {
    //   this.setCurrentGroupId(this.currentSupportGroups?.[0]?.group.id)
    // }
  }

  @action async createCommunityAssociatedGroups(data: any) {
    const res = await api.createAssociatedGroups(data)
    await this.getCommunityAssociatedGroups();
    return res;
  }

  @action async updateCommunityAssociatedGroups(entityId: number, data: any) {
    const res = await api.updateAssociatedGroups(entityId, data)
    await this.getCommunityAssociatedGroups();
    return res;
  }

  @action async getCommunityAssociatedGroups() {
    const res = await api.getAssociatedGroups()
    if (res?.data) {
      this.regionalCouncil.replace(res?.data)
    }
  }

  @action async getAssociatedLabels(council = 1) {
    const res = await api.getAssociatedLabels(council)
    this.associatedDefaultLabels = res?.data || []
  }

  @action async getGroups() {
    this.loadingGroups = true
    this.supportGroupsLoading = true
    if (location.pathname === Path.SERVICECALL) {
      await this.getSupportGroups()
      api.getGroups(orgs.currentOrgId  || 0).then(groups => {
        runInAction(() => {
          this.groups.replace(groups)
        })
      })
      this.initGroupId()
      this.setSupportLoading(false)

      this.loadingGroups = false
    }
    else {
      const groups = await api.getGroups(orgs.currentOrgId || 0)
      this.groups.replace(groups)
      this.getSupportGroups().then(() => {
        this.setSupportLoading(false)
      })
      this.initGroupId()
      this.loadingGroups = false
    }
  }

  @action getUserGroup = async (currentMemberId: number): Promise<UserGroup[]> => {
    if (orgs.currentOrgId) {
      const res = await api.getGroups(orgs.currentOrgId, currentMemberId)
      // const groupsIds = res?.groups?.map(i => {
      //   return i.id
      // })
      return res
    }
    return []
  }


  @action initGroupId = () => {
    const c = vls.get(storageKeys.currentGroupId)
    if ((location.pathname === Path.SERVICECALL && this.currentSupportGroups.length) || (this.currentGroups != null && this.currentGroups.length > 0)) {
      const first = (location.pathname === Path.SERVICECALL) ? this.currentSupportGroups?.[0]?.group.id : this.currentGroups[0].id
      this.setCurrentGroupId(this.currentGroupId ? first : (c || first))
    }
  }

  @action onGroupMembersChanged = () => {
    if (this.currentGroup) {
      const count = getMembersAndManagersCount(this.groupMembers)
      this.currentGroup.group.members = count.members
      this.currentGroup.group.orgainzers = count.managers
      orgs.setLocalCommunityGroups(Number(orgs.currentOrgId), this.groups)
    }
  }

  @observable loadingQueue: Set<string> = new Set<string>()

  @computed
  get isLoading() {
    return this.loadingQueue.size > 0
  }

  @action
  setLoading = (loading: boolean, id: string | LOADERS) => {
    loading ? this.loadingQueue.add(id) : this.loadingQueue.delete(id)
  }
  @action
  setSupportLoading = (loading: boolean,) => {
    this.supportGroupsLoading = loading
  }

  /** unused */
  @observable _groups: UserGroup[] = []

  @observable _groupMembers: IObservableArray<UserGroup> = observable([])

  @observable currentGroupId?: ID = undefined
  @observable currentGroupType: GroupType | null = null
  @observable mcEntityGroup: McEntity | null = null

  @computed get groupMembers(): UserGroup[] {
    return toJS(this._groupMembers)
  }
  @computed get currentGroup(): UserGroup | undefined {
    let group = this.groups?.find(g => g.group.id === this.currentGroupId) || undefined
    if (group === undefined) {
      group = this.supportGroups?.find(g => g.group.id === this.currentGroupId)
    }
    if (group === undefined) {
      group = this.openSupportCallGroups?.find(g => g.group.id === this.currentGroupId)
    }
    return group
  }

  // @computed get currentGroupMembers(): (UserGroup & User)[] {

  //   return this.groupMembers.map(gm => ({ ...gm, ...members.getById(gm.user_id) }))
  // }
  @computed get currentGroupUsers(): User[] {
    return (this.groupMembers.map(el => membersStore.getById(el.user)).filter((el: any) => el != undefined) as User[])
  }

  @computed get currentGroupNumberOfOrganizers() {
    // return this.currentGroupMembers.filter(member => { return member.is_organizer == true }).length
    return this.groupMembers.filter(userGroup => userGroup.is_member === true).length
  }
  @observable currentEditGroup: UserGroup | undefined = undefined
  @observable importUsersUserGroup: UserGroup | undefined = undefined
  @observable associatedLabels: AssociatedGroupsLabel | null = null
  @observable regionalCouncilSelected: serverCouncil | null = null
  @observable associatedDefaultLabels: string[] | null = null
  _users_by_id: Map<number, UserGroup> = new Map<number, UserGroup>()

  @computed get getAssociatedGroups() {
    const communityGroups = toJS(this.currentGroups).filter((g: Group) => g.groupLabels?.length > 0)
    let associatedGroups: { label: AssociatedGroupsLabel | string, group: null | Group }[] = [{ label: AssociatedGroupsLabel.remove, group: null },]
    
    this.associatedDefaultLabels?.map(label => {
      const newGroup = {
        label: label,
        group: null
      }
      associatedGroups.unshift(newGroup)
    })

    if (communityGroups.length > 0) {
      communityGroups.map((g: Group) => {
        associatedGroups.map((group: { label: AssociatedGroupsLabel, group: null | Group }) => {
          if (group.label == g?.groupLabels[0]) {
            group.group = g
          }
        })
      })
    }

    return associatedGroups
  }
  @action setSelectedConcil = (council: serverCouncil | null) => {
    this.regionalCouncilSelected = council
    this.getAssociatedLabels(council.id)
  }

  @action setAssociatedLabel = (label: AssociatedGroupsLabel | null) => {
    this.associatedLabels = label
  }

  @action setImportUserGroup = (userGroup: UserGroup | undefined) => {
    this.importUsersUserGroup = userGroup
  }
  @action setCurrentEditGroupName = (name: string) => {
    if (this.currentEditGroup?.group) {
      this.currentEditGroup.group.name = name
    }
  }

  @action setCurrentEditGroupDescription = (description: Description) => {
    if (this.currentEditGroup?.group) {
      this.currentEditGroup.group.description = description
    }
  }
  @action setCurrentEditGroupDuplex = (regularGroup: boolean, serviceCallGroup: boolean) => {
    if (this.currentEditGroup?.group) {
      let duplex = 0
      if (regularGroup && serviceCallGroup) {
        duplex = 2
      } else if (regularGroup && !serviceCallGroup) {
        duplex = 0
      } else if (!regularGroup && serviceCallGroup) {
        duplex = 1
      } else if (!regularGroup && !serviceCallGroup) {
        duplex = 3
      }
      this.currentEditGroup.group.duplex = duplex
    }
  }
  @action setCurrentEditGroupIsMember = (group_is_member: boolean) => {
    if (this.currentEditGroup != null) {
      this.currentEditGroup.group.group_is_member = group_is_member
    }
  }
  @action setCurrentEditIsSendingSmsOrEmail = (value: { isSendSms: boolean, isSendEmail: boolean}) => {
    if (this.currentEditGroup != null) {
      this.currentEditGroup.group.sendSMS = value.isSendSms
      this.currentEditGroup.group.sendEmail = value.isSendEmail
    }
  }

  @action setCurrentEditIsShare = (allowShare: boolean) => {
    if (this.currentEditGroup != null) {
      this.currentEditGroup.group.allowShare = allowShare
    }
  }

  @action setCurrentEditGroupIsSubscriber = (group_is_subscriber: boolean) => {
    if (this.currentEditGroup != null) {
      this.currentEditGroup.group.group_is_subscriber = group_is_subscriber
    }
  }

  @action isDisableToselectCouncil = () => {
    const currentOrg = orgs.orgs[`${orgs.currentOrgId}`]; 
    return  Array.isArray(currentOrg.mcEntities) && currentOrg.mcEntities.length > 0
  }

  @action getDefaultRegionalCouncil = () => {
    let defaultCouncil = this.regionalCouncil[0];
    const hasCouncil = this.isDisableToselectCouncil();
  
    if (hasCouncil) {
      const selectedCouncil = this.regionalCouncil.find(council => council.id == orgs.currentOrg.mcEntities[0].id); 
      defaultCouncil = selectedCouncil;
    }

    return defaultCouncil;
  }

  @action setCurrentEditGroupIsFavourite = (group_is_favourite: boolean) => {
    if (this.currentEditGroup != null) {
      this.currentEditGroup.group.group_is_favourite = group_is_favourite
    }
  }

  @action setCurrentEditGroupCanSend = (group_is_sender: boolean) => {
    if (this.currentEditGroup != null) {
      this.currentEditGroup.group.group_is_sender = group_is_sender
    }
  }
  @action getById = (id: number | undefined) => this.currentGroupId = id

  @observable isManageOpen: boolean = false

  @action setIsManageOpen = (state: boolean) => this.isManageOpen = state

  @observable isGroupModalOpen = false

  @action initGroupMembers = async (id: number) => {
    if (id < 0) {
      console.warn('getGroupMembers wont occur on groups below id 0')
      return;
    }
    const res = await this.getGroupMembers(id)
    //fix server not returning keys that are false
    //TODO: REMOVE FOREACH AFTER SERVER RETURNS ALL KEYS PROPERLY
    res.forEach((el: any) => {
      el.is_favourite = el.is_favourite || false
      el.is_member = el.is_member || false
      el.is_organizer = el.is_organizer || false
      el.is_sender = el.is_sender || false
      el.is_subscriber = el.is_subscriber || false
      el.is_hide = el.is_hide || false
      el.user = el.user_id || el.user// getgroupmembers api uses user_id
      el.userData = membersStore.data.get(el.user_id || el.user) || {
        "is_showInAlphon": false,
        "displayName": el.user_full_name,
        "emails": [],
        "family_name": el.family_name,
        "fullName": el.user_full_name,
        "given_name": el.given_name,
        "nick_name": el.nick_name,
        "phones": [],
        "user_id": el.user,
      }
    })

    const group = toJS(this.currentGroup?.group)
    res.forEach((el: UserGroup) => {//currently server doesnt return group
      if (group) {
        el.group = group
      }
    })

    this._users_by_id.clear()
    res.forEach((el: UserGroup) => {
      this._users_by_id.set(el.user_id, el)
    })
    const sortedlist = res.sort((a, b) => !a.userData?.family_name ? 1 : (!b.userData?.family_name ? -1 : (a.userData?.family_name.localeCompare(b.userData?.family_name, 'he', { sensitivity: 'base' }))))
    this._groupMembers.replace(sortedlist as UserGroup[])
  }

  getById = (id: ID): UserGroup | undefined => {
    return this._users_by_id.get(id)
  }

  @action getGroupMembers = async (id: number) => {
    if (id < 0) {
      console.warn('getGroupMembers wont occur on groups below id 0')
      return [];
    }
    const res = await api.getGroupUsers(id)
    return res;
  }

  @action setCurrentGroupId = (id: number | undefined) => {
    this.currentGroupId = id
  }
  @action setCurrentGroupType = (type: GroupType) => {
    this.currentGroupType = type
  }
  @action setMcEntityGroup = (group: McEntity | null) => {
    this.mcEntityGroup = group
  }

  manage = () => this.setIsManageOpen(true)

  addGroup = () => {
    this.currentEditGroup = {
      group: {
        name: '',
        description: '',
        id: null, //TODO,fix this typescript error
        community_id: Number(orgs.currentOrgId),
        duplex: 0,
        group_is_member: true,
        group_is_subscriber: true,
        group_is_favourite: true,
        group_is_sender: false,
        allowShare: false,
        sendSMS: false,
        sendEmail: false
      },
      user: userStore.user?.user_id,
      community: Number(orgs.currentOrgId)
    }
    this.isGroupModalOpen = true
    this.associatedLabels = null
  }

  close = () => {
    this.isGroupModalOpen = false
    this.setIsManageOpen(false)
  }

  @action addUsersToGroup = async (groupId: number) => {
    //TODO this function seems very wired, no userData
    let responseUsers: User[] = await api.getGroupUsers(this.importUsersUserGroup ? this.importUsersUserGroup.group.id : groupId)
    let groupUsers = responseUsers.filter(u => u.user_id != userStore.user.user_id)
    if (this.importUsersUserGroup) {
      const newGroupUsers: UserGroup[] = groupUsers.map(el => ({
        is_subscriber: this.currentEditGroup?.group.group_is_subscriber,
        is_favourite: this.currentEditGroup?.group.group_is_favourite,
        user: el.user_id,
        is_member: true,
        is_sender: this.currentEditGroup?.group.group_is_sender,
        allowShare: this.currentEditGroup?.group.allowShare,
        sendEmail: this.currentEditGroup?.group.sendEmail,
        sendSMS: this.currentEditGroup?.group.sendSMS,
        is_organizer: false,
        group: { id: groupId },
        community: orgs.currentOrgId || 0,
      }))
      await api.updateGroupUsers(newGroupUsers)
      this._groupMembers.replace(newGroupUsers)
      return
    }
  }

  @action updateGroup = async () => {
    if (!this.currentEditGroup) {
      return
    }
    if (this.currentEditGroup.group.id < 0) {
      const parent_id = this.currentEditGroup?.group?.parent_id
      if (parent_id) {
        this.currentEditGroup.group.id = parent_id
      }
    }
    const res = await api.updateGroup(this.currentEditGroup)
    if (!res?.status || res.status < 400) {
      //if duplex has changed, updated support groups from server again.
      if (this.currentEditGroup.group.duplex != this.currentGroup?.group.duplex) {
        // if group changed from regular to service only:
        if (this.currentGroup?.group.type === GroupType.REGULAR && this.currentEditGroup.group.duplex === 1) {
          await this.getSupportGroups()
          const found = this.supportGroups.find(el => Math.abs(el.group.id) === this.currentGroup?.group.id)
          if (found) {
            this.setCurrentGroupId(found.group.id)
          }
          this.groups.replace(this.groups.filter(el => el.group.id != this.currentEditGroup?.group.id))
        }
        else if (this.currentGroup?.group.type === GroupType.REGULAR && (this.currentEditGroup.group.duplex === 0 || this.currentEditGroup.group.duplex === 2)) {
          await this.getSupportGroups()
        }
        // if group changed from service only to regular only:
        else if (this.currentGroup?.group.type === GroupType.SUPPORT && (this.currentEditGroup.group.duplex === 0 || this.currentEditGroup.group.duplex === 2)) {
          const newRegularGroup = deepClone(this.currentEditGroup)
          newRegularGroup.group.type = GroupType.REGULAR
          newRegularGroup.group.parent_id = undefined
          newRegularGroup.group.id = Math.abs(newRegularGroup.group.id)
          const index = this.groups.findIndex(el => el.group.id === newRegularGroup.group.id)
          if (index != -1) {
            this.groups[index] = newRegularGroup
          } else {
            this.groups.push(newRegularGroup)
          }
          if (this.currentEditGroup.group.duplex === 0) {
            this.setCurrentGroupId(newRegularGroup.group.id)
          }
          await this.getSupportGroups()
        }
        else if (this.currentGroup?.group.type === GroupType.SUPPORT && this.currentEditGroup.group.duplex === 1) {
          this.groups.replace(this.groups.filter(el => el.group.id != Math.abs(this.currentEditGroup?.group.id || 0)))
        }

      }
      let groups: IObservableArray<UserGroup>;
      // let groupUsers = await api.getGroupUsers(this.currentGroupId > 0 ? this.currentGroupId : userGroup.parent_id)
      await this.initGroupMembers(this.currentEditGroup.group.id)
      // let groupUsers = this._groupMembers
      // const membersManagersCount = getMembersAndManagersCount(groupUsers)
      // this.currentEditGroup.group.members = membersManagersCount.members
      // this.currentEditGroup.group.orgainzers = membersManagersCount.managers
      groups = observable([...this.groups.filter(el => el.group.id === this.currentEditGroup?.group.id),
      ...this.supportGroups.filter(el => Math.abs(el.group.id) === this.currentEditGroup?.group.id),
      ...this.openSupportCallGroups.filter(el => el.group.parent_id === this.currentEditGroup?.group.id)
      ])
      groups.forEach((el: UserGroup) => {
        // el.group.members = membersManagersCount.members
        // el.group.orgainzers = membersManagersCount.managers
        el.group.name = this.currentEditGroup?.group.name || ''
        el.group.duplex = this.currentEditGroup?.group.duplex || 0
        // el.group.type = (this.currentEditGroup?.group.duplex === 1 || this.currentEditGroup?.group.duplex === 2) ? GroupType.SUPPORT : GroupType.REGULAR
        el.group.description = el.group.description ? Object.assign(el.group.description, this.currentEditGroup?.group?.description || {}) : (this.currentEditGroup?.group?.description || '')
        el.group.group_is_favourite = this.currentEditGroup?.group.group_is_favourite || false
        el.group.group_is_subscriber = this.currentEditGroup?.group.group_is_subscriber || false
        el.group.group_is_member = this.currentEditGroup?.group.group_is_member || false
        el.group.group_is_sender = this.currentEditGroup?.group.group_is_sender || false
        el.group.allowShare = this.currentEditGroup?.group.allowShare || false
        el.group.sendEmail = this.currentEditGroup?.group.sendEmail || false
        el.group.sendSMS = this.currentEditGroup?.group.sendSMS || false

        el.group.groupLabels = this.associatedLabels ? this.associatedLabels == AssociatedGroupsLabel.remove ? [] : [this.associatedLabels] : el.group.groupLabels
        el.group.mcEntity = this.regionalCouncilSelected?.name || null
        el.group.mcEntityID = this.regionalCouncilSelected?.id || null
        el.is_subscriber = this.currentEditGroup?.group.group_is_subscriber || false
        el.is_favourite = this.currentEditGroup?.group.group_is_favourite || false
        el.is_member = this.currentEditGroup?.group.group_is_member || false
        el.is_sender = this.currentEditGroup?.group.group_is_sender || false
        el.group.sendSMS = this.currentEditGroup?.group.sendSMS || false
        el.group.sendEmail = this.currentEditGroup?.group.sendEmail || false
      })

    }
  }

  @action createGroup = async () => {
    if (!this.currentEditGroup) {
      return
    }
    const res = await api.createGroup(this.currentEditGroup)
    if (!res?.status || res.status < 400) {
      const createdId = res.group_id
      await this.addUsersToGroup(createdId)

      const membersManagersCount = getMembersAndManagersCount(this._groupMembers)
      this.currentEditGroup.group.groupLabels = this.associatedLabels ? (this.associatedLabels == AssociatedGroupsLabel.remove) ? [] : [this.associatedLabels] : null
      this.currentEditGroup.group.mcEntity = this.regionalCouncilSelected?.name || null
      this.currentEditGroup.group.mcEntityID = this.regionalCouncilSelected?.id || null
      this.currentEditGroup.group.id = createdId
      this.currentEditGroup.group.members = membersManagersCount.members
      this.currentEditGroup.group.orgainzers = membersManagersCount.managers
      this.currentEditGroup.group.type = GroupType.REGULAR
      this.currentEditGroup.is_favourite = this.currentEditGroup.group.group_is_favourite
      this.currentEditGroup.is_subscriber = this.currentEditGroup.group.group_is_subscriber
      this.currentEditGroup.is_member = this.currentEditGroup.group.group_is_member
      this.currentEditGroup.is_organizer = this.currentEditGroup.group.is_organizer || true
      this.currentEditGroup.is_sender = this.currentEditGroup.group.group_is_sender
      this.currentEditGroup.allowShare = this.currentEditGroup.group.allowShare
      this.currentEditGroup.sendEmail = this.currentEditGroup.group.sendEmail
      this.currentEditGroup.sendSMS = this.currentEditGroup.group.sendSMS


      if (this.currentEditGroup.group.duplex === 1) {
        this.currentEditGroup.group.type = GroupType.SUPPORT
        this.currentEditGroup.group.is_organizer = true
        this.currentEditGroup.group.id = -createdId
        this.supportGroups.push(this.currentEditGroup)
        this.supportGroups.replace(this.supportGroups.slice().sort((a, b) => {
          return a?.group?.name.localeCompare(b?.group?.name)
        }))
      }
      else {
        this.groups.push(this.currentEditGroup)
        this.groups.replace(this.groups.slice().sort((a, b) => {
          return a?.group?.name.localeCompare(b?.group?.name)
        }))
        if (this.currentEditGroup.group.duplex === 2) {
          const clonedSupportGroup = deepClone(this.currentEditGroup)
          clonedSupportGroup.group.type = GroupType.SUPPORT
          clonedSupportGroup.group.is_organizer = true
          clonedSupportGroup.group.id = -createdId
          clonedSupportGroup.group.parent_id = createdId
          this.supportGroups.push(clonedSupportGroup)
          this.supportGroups.replace(this.supportGroups.slice().sort((a, b) => {
            return a?.group?.name.localeCompare(b?.group?.name)
          }))
        }
      }
    }
  }

  @action createUpdateGroup = async () => {
    const isCreateGroup = !Boolean(this.currentEditGroup?.group?.id)

    if (isCreateGroup) {
      console.log('createGroup')
      await this.createGroup()
    } else {
      if (userCanSeeAssociatedGroups()) {  
        if (this.isDisableToselectCouncil() && !this.regionalCouncilSelected) {
          const selectedCouncil = this.regionalCouncil.find(council => council.id == orgs.currentOrg.mcEntities[0].id); 
          this.setSelectedConcil(selectedCouncil);
        }
      }
      
      console.log('updateGroup')
      await this.updateGroup();
    }


    if (userCanSeeAssociatedGroups() && this.associatedLabels && this.regionalCouncil.length > 0 && this.regionalCouncilSelected) {
      if (this.associatedLabels == AssociatedGroupsLabel.remove) {
        api.setAssociatedGroups(this.regionalCouncilSelected.id, this.currentEditGroup?.group.id, [])
        await orgs.initOrgs();

      } else {
        api.setAssociatedGroups(this.regionalCouncilSelected.id, this.currentEditGroup?.group.id, [this.associatedLabels])
        await orgs.initOrgs();
      }
    }
    //if (isCreateGroup) {
      this.setCurrentGroupId(this.currentEditGroup?.group.id)
    //}
    this.currentEditGroup = undefined
    this.importUsersUserGroup = undefined
    this.associatedLabels = null
  }


  editGroup = () => {
    this.currentEditGroup = deepClone(this.currentGroup)
    this.associatedLabels = null
  }

  @action removeGroup = async () => {
    const data = {
      id: 1,
      group: (this.currentGroupId && (this.currentGroupId > 0)) ? this.currentGroupId : this.currentGroup?.group?.parent_id,
      community: orgs.currentOrgId,
    }
    const res = await api.removeGroup(data)
    if (!res?.status || res.status < 400) { // manually remove group from store upon success
      if (this.groups.find((userGroup) => userGroup.group.id === this.currentGroupId)) {
        this.groups.replace(this.groups.filter((userGroup) => userGroup.group.id !== this.currentGroupId))
        orgs.setLocalCommunityGroups(Number(orgs.currentOrgId), this.groups)
        this.setCurrentGroupId(this.currentGroups[0]?.id)
      } else if (this.supportGroups.find((userGroup) => userGroup.group.id === this.currentGroupId)) {
        this.supportGroups.replace([...this.supportGroups.filter((userGroup) => userGroup.group.id !== this.currentGroupId)])
        this.setCurrentGroupId(this.supportGroups[0]?.group.id)
      } else if (this.openSupportCallGroups.find((userGroup) => userGroup.group.id === this.currentGroupId)) {
        this.openSupportCallGroups.replace([...this.openSupportCallGroups.filter((userGroup) => userGroup.group.id !== this.currentGroupId)])
        this.setCurrentGroupId(this.openSupportCallGroups[0]?.group.id)
      }


      // orgs.onTreeGroupClick(String(orgs.currentOrgId), this.groups[1].group.id)
    }
  }

  @action createLabelAssociatedEntity = async (entityId, data) => {
    return api.createLabelAssociatedEntity(entityId, data)
  }

  @action addMembers = async (users: User[]) => {
    if (users && this.currentGroup) {
      this._groupMembers.push(...users.map<UserGroup>(el => ({
        // ...el,
        is_subscriber: this.currentGroup?.group.group_is_subscriber ? true : false,
        is_favourite: this.currentGroup?.group.group_is_favourite ? true : false,
        user: el.user_id,
        is_member: true,
        is_sender: this.currentGroup?.group.group_is_sender ? true : false,
        is_organizer: false,
        allowShare: !!this.currentGroup?.group.allowShare,
        sendEmail: !!this.currentGroup?.group.sendEmail,
        sendSMS: !!this.currentGroup?.group.sendSMS,
        group: this.currentGroup!.group,
        community: this.currentGroup!.community,
        userData: membersStore.getById(el?.user_id)
      })))

      const currentLoc = this.currentGroup?.group
      if (currentLoc) {
        currentLoc.members = this._groupMembers.length
      }
      //this.currentGroup?.group.members = this.groupMembers.length
      const currentGroupClone = deepClone(this.currentGroup.group)
      if (currentGroupClone?.id && currentGroupClone.parent_id && (currentGroupClone.id < 0)) {
        currentGroupClone.id = currentGroupClone.parent_id
      }
      const userGroup: UserGroup[] = users.map(el => ({
        // ...this.currentGroup,

        is_subscriber: this.currentGroup?.group.group_is_subscriber ? true : false,
        is_favourite: this.currentGroup?.group.group_is_favourite ? true : false,
        user: el.user_id,
        is_member: true,
        is_sender: this.currentGroup?.group.group_is_sender ? true : false,
        allowShare: !!this.currentGroup?.group.allowShare,
        sendEmail: !!this.currentGroup?.group.sendEmail,
        sendSMS: !!this.currentGroup?.group.sendSMS,
        is_organizer: false,
        group: currentGroupClone,
        community: this.currentGroup!.community,

      }))
      // return api.addMembersToGroup(orgs.currentOrgId, this.currentGroupId, users)
      return api.updateGroupUsers(userGroup)
    }
  }

  @action removeMembers = (userId: number) => {

    const userGroup = this._groupMembers.find(userGroup => userGroup.user === userId || userGroup.user_id === userId)
    if (userGroup) {
      userGroup.is_member = false
      api.updateUserGroups([userGroup], true, true)
      this._groupMembers.remove(userGroup)
      const currentLoc = this.currentGroup?.group
      if (currentLoc) {
        currentLoc.members = this._groupMembers.length
      }
    }
  }

  @action removeAllMembers = () => {
    const groupHasFreinds = this._groupMembers.length > 0
    if (groupHasFreinds) {
      const usersGroups = this._groupMembers.map(user => {
        user.is_member = false
        return user
      })
      api.updateGroupUsers(usersGroups, true)
      this._groupMembers.splice(0, this._groupMembers.length)
      const currentLoc = this.currentGroup?.group
      if (currentLoc) {
        currentLoc.members = this._groupMembers.length
      }
    }
  }

  @action changeManagerState = async (userId: number, isManager: boolean) => {
    const userGroup = this._groupMembers.find(userGroup => userGroup.user === userId || userGroup.user_id === userId)
    if (userGroup) {
      userGroup.is_organizer = isManager ? false : true
      userGroup.is_sender = isManager ? userGroup.group.group_is_sender : true
      const res = await api.updateUserGroups([userGroup], true)
      if (res?.status && res.status >= 400) {
        userGroup.is_organizer = isManager ? true : false
        userGroup.is_sender = isManager ? true : userGroup.group.group_is_sender
      }
    }
  }
  @action hideShowUserGroup = async (userGroup: UserGroup, isFavourite: boolean) => {
    const foundGroup = this.currentUserGroups.find(el => el.group.id === userGroup.group.id)
    if (foundGroup) {
      foundGroup.is_favourite = isFavourite
      if (foundGroup === this.currentGroup && isFavourite === false) {
        this.setCurrentGroupId(this.currentUserGroups.find(el => el.is_favourite && el.group.id != userGroup.group.id)?.group?.id)
      }
      else if (isFavourite === true && !this.currentGroup) {
        this.setCurrentGroupId(userGroup.group.id)
      }
    }
    userGroup.is_favourite = isFavourite
    await api.updateUserGroups([userGroup])
  }

  @action setGroupProvider = async (provider: Provider) => {
    if (this.currentGroupId) {
      const res = await api.setGroupProvider(Math.abs(this.currentGroupId), provider.providerId)
      if (this.currentGroup) {
        this.currentGroup.group.providerId = provider.providerId
        this.currentGroup.group.providerName = provider.providerName
      }
      return res
    }
  }
  @action deleteGroupProvider = async () => {
    if (this.currentGroupId) {
      const res = await api.deleteGroupProvider(Math.abs(this.currentGroupId))
      if (this.currentGroup) {
        this.currentGroup.group.providerId = null
        this.currentGroup.group.providerName = null
      }
      return res
    }
  }
}

const groupsStore = new GroupsStore()
export default groupsStore


