import React, { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { TextField, TableCell, TableRow, Link, Button } from '@material-ui/core'
import { Edit, Delete, Add, Close, Save, Link as LinkIcon } from '@material-ui/icons'
import { snackBar } from 'store'
import styled from 'styled-components'
import { Switch } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import useStore from 'store'
import api from 'service/api'

const Actions = styled.div`
  display:flex;
  flex-direction: row;
`
const AutoCompleteContainer = styled(Autocomplete)`
  margin:10px 0;
  width: 100%;
`
const StyledButton = styled(Button)`
  padding: 2px 4px;
  min-width: 10px;
`

const getPriorityTitleByValue = (val) => val == 0 ? 'רגיל' : 'גבוהה'
const genericErrorText = 'קיימת בעיה בשרת כרגע. אנא נסו מאוחר יותר.'

const LabelSectionNewItem: FC = ({ onRefresh, onClose, entityId, entityLabels = [] }) => {
    const {t} = useTranslation('communitySettings')
    const { uiStore } = useStore()
    const [labelFormState, setLabelFormState] = useState({
        name: '',
        sendingPriority: {value: 0, name: getPriorityTitleByValue(0) },
        status: 1,
    })

    const onSaveEvent = async () => {
        const res = await api.createLabel({
          name: labelFormState.name,
          description: labelFormState.name,
          sendingPriority: labelFormState.sendingPriority.value,
          status: 1,
        });

        if (res === null) {
          uiStore.openWarningModal({
            text: 'תג זה כבר קיים באירגון אחר האם ברצונך להישתמש בו?',
            secondText: labelFormState.name,
            okText: `הוסף`,
            cancelText:t`ביטול`,
            okAction: async () => {
              onAttachEvent()
            }
          })
          return
        }

        if (!res) {
          return
        }

        await api.linkLabelWithEntity(entityId, res[0].id)
    
        onClose()
        onRefresh(entityId)
      }

      const onAttachEvent = async () => {
        const labels = await api.getAllLabels()
        if (!Array.isArray(labels)) {
          snackBar.showError(genericErrorText)
          return
        }
  
        const existLabel = labels.find(l => l.name == labelFormState.name)

        if (!existLabel) {
          snackBar.showError(genericErrorText)
        }

        if (existLabel) {
          await api.linkLabelWithEntity(entityId, existLabel.id)
          onClose()
          onRefresh(entityId)
        }
      }
  
      const isDisabled = () => {
        const labelNames = entityLabels.map(l => l.name)
        return labelFormState.name.trim() == '' || labelNames.includes(labelFormState.name.trim())
      }

    return (  
      <>
      <TableRow>
        <TableCell component="th" scope="row" align="left">
          <AutoCompleteContainer
            value={labelFormState.sendingPriority}
            defaultValue={{value: 0, name: 'רגיל'}}
            disableClearable
            onChange={(value: any, v: any) => {
              setLabelFormState({ ...labelFormState, sendingPriority: v })
            }}
            options={[{value: 1, name: 'גבוהה'}, {value: 0, name: 'רגיל'}]}
            placeholder={`תיעדוף`}
            renderOption={(option: any) => option?.name}
            getOptionLabel={(option: any) => option?.name}
            renderInput={(params) =>
              <TextField {...params} value={labelFormState.sendingPriority.value || 0} label={`תיעדוף`} variant="outlined" />}
          />
        </TableCell>
        <TableCell align="left">
          <TextField
            id={'label_name'}
            required
            label={t`name`}
            variant="outlined"
            value={labelFormState.name}
            onChange={({target}) => {
              setLabelFormState({ ...labelFormState, name: target.value })
            }}
          />
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          <Switch
            color="primary"
            disabled
            value={labelFormState.status ? 1 : 0}
            onChange={({target}) => {
              //setCouncilFormState({ ...councilFormState, status: target.checked ? 1 : 0 })
            }}
            checked={labelFormState.status ? 1 : 0}
          />
        </TableCell>
        <TableCell align="left" width={100}>
          <Actions>
            <StyledButton disabled={isDisabled()} onClick={onSaveEvent}><Save/></StyledButton>
          </Actions>
        </TableCell>
      </TableRow>
      </>
    )
}

export default observer(LabelSectionNewItem)